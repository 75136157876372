import React, { useState, useEffect, useRef } from "react"

import axios from "axios"

import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"
import { getAuth, sendPasswordResetEmail } from "firebase/auth"
import { useForm } from "react-hook-form"
import { motion, AnimatePresence } from "framer-motion"
import classnames from "classnames"
import isEqual from "lodash.isequal"

/* Import Global Hook(s) */
import usePreviousValue from "~hooks/usePreviousValue"

/* Import Page Component(s) */
import VipPage from "~components/pages/vip/vipPage/vipPage"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Local Style(s) */
import "./vip-reset.scss"

const VIPReset = ({ location }) => {
  const [feedback, setFeedback] = useState()
  const formRef = useRef()
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm({ mode: "onChange" })
  const allFieldsState = watch()
  const previousAllFieldsState = usePreviousValue(allFieldsState)

  useEffect(() => {
    if (feedback) {
      if (!isEqual(allFieldsState, previousAllFieldsState)) {
        setFeedback()
      }
    }
  }, [allFieldsState, previousAllFieldsState, feedback, setFeedback])

  const onSubmit = ({ email }) => {
    if (!email) {
      return
    }

    console.log('account reset password attempt...')
    console.log(email)

    const data = { email }
    const path = '/vips/auth/forgot-password'

    setFeedback('Submitting reset...')
    axios
    .post("/.netlify/functions/vipUsers", {
      contents: data,
      path: path
    })
    .then(res => {

      console.log(res)
      setFeedback('If there is a VIP record associated with this email you will receive an email to login shortly.')
      // setFeedback('If this address is attached to an account, you will receive an email with with a reset password link shortly.')
      setTimeout(() => {
        setFeedback(false)
      }, 10000)

    })
    .catch(e => {
      setFeedback('There was an error processing your request. Please try again.')
      setTimeout(() => {
        setFeedback(false)
      }, 5000)
      console.error(e)
    })

  }

  return (
    <VipPage title="VIP Reset" header="VIP Reset" location={location}>
      <form onSubmit={handleSubmit(onSubmit)} className="log-in vip-reset" ref={formRef}>
        <input
          type="email"
          placeholder="Email Address"
          {...register("email", { required: true })}
        />
        <AnimatePresence initial={false}>
          {!feedback && (
            <input
              className="is-valid"
              type="submit"
              value="Reset"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={elementTransitionAnimation.variants}
            />
          )}
          {feedback && (
            <motion.p
              className="feedback"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={elementTransitionAnimation.variants}
            >
              {feedback}
            </motion.p>
          )}
        </AnimatePresence>
      </form>
      <div className="links">
        <Link to="/vip/">Login</Link>
      </div>
    </VipPage>
  )
}

export default VIPReset
