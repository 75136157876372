import React from "react"

/* Import Global Component(s) */
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"

/* Import Local Style(s) */
import "./vip-page.scss"

const VipPage = ({ title, header, location, children }) => {
  return (
    <Page className="page__vip-page" title={title} location={location}>
      <PageHeader title={header} location={location} />
      <PageArticle>{children}</PageArticle>
      <Footer />
    </Page>
  )
}

export default VipPage
